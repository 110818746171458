.services {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.service-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-top: 32px;
  width: 304px;
  height: auto;
  min-height: 430px;
  border-radius: 16px;
  color: #FFF;
  background-color: #FB6219;
  transition: all .4s;
}

.service-card-row {
  display: flex;
  flex-direction: row;
}

.service-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.service-card-best-seller {
  background-color: #2664C4;
}

.service-card-best-seller > .service-best-seller {
  color: #FB6219;
}

.service-card-best-seller > .service-button {
  background-color: #FFF;
  color: #2664C4;
}

.service-velocity-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.service-velocity {
  font-size: 56px;
  font-weight: 900;
}

.service-velocity-category {
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0 0 4px;
}

.service-type {
  font-size: 24px;
  font-weight: 400;
  margin-top: 16px;
}

.service-value {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  margin-top: 16px;
}

.service-value > span:last-child {
  font-size: 18px;
  font-weight: 200;
  margin-top: 1px;
}

.service-value > span:first-child {
  font-size: 14px;
  font-weight: 200;
  align-self: flex-start;
  margin-top: 4px;
  margin-right: -4px;
}

.service-best-seller {
  font-size: 16px;
  min-height: 16px;
  font-weight: 100;
  font-style: italic;
  color: #FFF;
  margin: 24px 0;
}

.service-description {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

.service-benefits-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.service-benefit-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.service-benefit-name {
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
}

.service-button {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  background-color: #2664C4;
  color: #FFF;
  transition: all .4s;
}

.includes-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  padding-top: 24px;
}

.includes-view > span {
  font-size: 12px;
  text-align: center;
  margin-bottom: 8px;
}

.includes-view > img {
  width: 100%;
  margin-bottom: 8px;
}
