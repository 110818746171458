.alert-styled {
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}
