.service-button:disabled {
  background-color: #8496b1;
  cursor: not-allowed;
}

.cancel-button {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  background-color: #ff4e4e;
  color: #FFF;
  transition: all .4s;

}
